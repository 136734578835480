/**
 * Created by johnny on 18/08/16
 */
import React from 'react'
import jsPDF from 'jspdf'
import {EMOJI_REGEX, pathServer} from '../common/Constants'

export const getPrimaryColor = (primaryColor) => {
  let primary = primaryColor
  if(primaryColor === "#000000" || primaryColor === "#000")
    primary = "#FFF"
  return primary
}

function toDataURL(src, callback, outputFormat) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = '*';
    img.onload = function () {
      const canvas = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(src);
      callback(dataURL,canvas.width, canvas.height,outputFormat);
      setTimeout(() => resolve(img),3000);
    };

    img.onerror = () => reject(
      outputFormat()
    )

    img.src = src + "?abc";
    if (img.complete || img.complete === undefined) {
      img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  });
}

export function createPDFFile(car, primaryColor, location = {}, closeSpinner, carImages, dealer){
  let doc = new jsPDF('p', 'px', [595,800]);
  const {address = '', city = '', state = '', zip = '', info = '{}'} = location
  const {email = '', phoneNumber = ''} = JSON.parse(info)
  let title = `${car.year} ${car.makeName} ${car.modelName}`
  const carPrice =  (car.suggestedPrice && car.suggestedPrice !== 0) ?  `$${car.suggestedPrice}`: ``
  const description = car.description !== "" ? car.description : 'This car has not description yet.'
  const carFuel = car["car.fuel"] ? car["car.fuel"].attributeValue : "N/A"
  const carTitleType = car["car.title"] ? car["car.title"].attributeValue : "N/A"
  const carBodyStyle = car["car.body"] ? car["car.body"].attributeValue : "N/A"
  const carDrive = car["car.drive"] ? car["car.drive"].attributeValue : "N/A"
  const carCylinder = car["car.cylinder"] ? `${car["car.cylinder"].attributeValue} cylinders` : "N/A"
  const locationAddress = `${address} ${city}${state ? `, ${state}` : ''}${zip ? `, ${zip}` : ''}`

  /** dealerInformation **/
  const {logo, dealerName } = dealer

  doc.setProperties({
    title: title,
    description: description,
    price: carPrice
  });
  let descriptionWithoutEmojis = description.replace(EMOJI_REGEX,'');
  //All Document to print
  doc.setFont('Open Sans', 'bold');
  doc.setFontSize(15);
  doc.setTextColor(77,79,92)
  doc.text(title, 20, 145, {align: "left", maxWidth: 200});
  const widthText = doc.getTextWidth(title)
  doc.setTextColor(15, 190, 44)
  doc.text(carPrice, 30 + widthText , 145, {align: "left", maxWidth: 200});
  doc.setDrawColor(209, 12, 12)
  doc.setLineWidth(1)
  doc.line(20, 150, 100, 150)

  /** Icon's text **/
  doc.setTextColor(77,79,92)
  const minSeparation = 50
  const widthFuel = doc.getTextWidth(carFuel) !== 0 ? doc.getTextWidth(carFuel) : minSeparation
  const widthTitleType = doc.getTextWidth(carTitleType) !== 0 ? widthFuel + doc.getTextWidth(carTitleType) : minSeparation + widthFuel
  const widthBodyStyle = doc.getTextWidth(carBodyStyle) !== 0 ? widthTitleType + doc.getTextWidth(carBodyStyle) : minSeparation + widthTitleType
  const widthDrive = doc.getTextWidth(carDrive) !== 0 ? widthBodyStyle + doc.getTextWidth(carDrive) : minSeparation + widthBodyStyle
  doc.setFont('Open Sans', 'normal');
  doc.setFontSize(12);
  const positionInitial = 20
  const widthIcon = 15
  doc.text(carFuel, positionInitial + widthIcon, 165, {align: "left", maxWidth: 200});
  doc.text(carTitleType, positionInitial + 2*widthIcon + widthFuel, 165, {align: "left", maxWidth: 200});
  doc.text(carBodyStyle, positionInitial + 3*widthIcon + widthTitleType, 165, {align: "left", maxWidth: 200});
  doc.text(carDrive, positionInitial + 4*widthIcon + widthBodyStyle , 165, {align: "left", maxWidth: 200});
  doc.text(carCylinder, positionInitial + 5*widthIcon + widthDrive, 165, {align: "left", maxWidth: 200});

  /** icons **/
  let srcList = [
    `${pathServer.PATH_IMG}car_fuel.png`,
    `${pathServer.PATH_IMG}car_title_type.png`,
    `${pathServer.PATH_IMG}car_body_style.png`,
    `${pathServer.PATH_IMG}gear.png`,
    `${pathServer.PATH_IMG}car_cylinder.png`
  ]

  /** car images **/
  if(carImages.length !== 0){
    if(carImages.length > 2){
      srcList.push(carImages[0].url)
      srcList.push(carImages[1].url)
      srcList.push(carImages[2].url)
    }else if (carImages.length === 2){
      srcList.push(carImages[0].url)
      srcList.push(carImages[1].url)
      srcList.push("noImage")
    }else if(carImages.length === 1){
      srcList.push(carImages[0].url)
      srcList.push("noImage")
      srcList.push("noImage")
    }
  }

  /** position icons**/
  const positionList = [
    {x: positionInitial, y: 158, width: 10, height: 10},
    {x: positionInitial + widthIcon + widthFuel, y: 158, width: 10, height: 10},
    {x: positionInitial + 2*widthIcon + widthTitleType, y: 158, width: 10, height: 10},
    {x: positionInitial + 3*widthIcon + widthBodyStyle, y: 158, width: 10, height: 10},
    {x: positionInitial + 4*widthIcon + widthDrive, y: 158, width: 10, height: 10}
  ]

  if(carImages.length !== 0){
    positionList.push({x: 20, y: 40, width:130 ,height: 84.5})
    positionList.push({x: 160, y: 40, width:130 ,height: 84.5})
    positionList.push({x: 300, y: 40, width:130 ,height: 84.5})
  }

  /** Logo **/
  if(logo !== ""){
    srcList.push(logo)
    positionList.push({x: 20, y: 14, width: 1 ,height: 18})
  }

  /** car description**/
  doc.setTextColor(77,79,92)
  doc.setFont('Open Sans', 'normal');
  doc.setFontSize(15);
  doc.text("Dealer Description",20, 195, {maxWidth:200})
  doc.setFontSize(11);
  if(car.description === "")
    doc.setTextColor(226, 226, 226)
  const descriptionCut =  descriptionWithoutEmojis.length > 1600 ? `${descriptionWithoutEmojis.substring(0,1000)}...` : descriptionWithoutEmojis
  let arrayDescription = descriptionCut.split("\n").map( element => element + "\n")
  doc.text( arrayDescription, 20, 210 , {align: "left", maxWidth: 200});

  /** table of features **/
  doc.setTextColor(77, 79, 92);
  const tableHeaders = createHeaders(['feature', 'value']);
  const tableBody = getCarFeatures(car)
  doc.table(230, 190, tableBody, tableHeaders, {autoSize: true, printHeaders: false});

  /** footer **/
  doc.setFillColor(244, 244, 244);
  doc.rect(0, 550, 450, 80, 'F');
  /** location info **/
  doc.setTextColor(0, 0, 0)
  doc.setFont('Open Sans', 'bold');
  doc.setFontSize(12);
  doc.text(dealerName, 20, 570);
  doc.setFontSize(10);
  doc.setFont('Open Sans', 'normal');
  doc.text(locationAddress, 20, 580);
  doc.text(phoneNumber, 145, 570);
  doc.text(email, 145, 580);
  doc.text(window.location.hostname, 325, 570);

  /** Images **/
  if(carImages.length === 0){
    doc.setFontSize(12);
    for (let i = 0; i < 3; i++) {
      doc.setFillColor(226, 226, 226)
      doc.rect(20 + 140*i, 40, 130, 84.5, 'F')
      doc.setTextColor(127,127,127)
      doc.text('No image', 60 + 140*i, 82.25);
    }
    processImages(srcList,positionList,doc, closeSpinner)
  }else{
    processImages(srcList,positionList,doc, closeSpinner)
  }

}

function getCarFeatures(car) {
  return [
    {
      feature: 'Price',
      value: (car.suggestedPrice !== '') ? '$ ' + car.suggestedPrice : 'Not Specified'
    },
    {
      feature: 'Year',
      value: (car.year && car.year !== '') ? car.year.toString() : 'Not Specified'
    },
    {
      feature: 'Make',
      value: (car.makeName && car.makeName !== '') ? car.makeName : 'Not Specified'
    },
    {
      feature: 'Model',
      value: (car.modelName && car.modelName !== '') ? car.modelName : 'Not Specified'
    },
    {
      feature: 'Trim',
      value: (car['car.trim'] && car['car.trim'].attributeValue !== '') ? car['car.trim'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Body',
      value: (car['car.body'] && car['car.body'].attributeValue !== '') ? car['car.body'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Mileage',
      value: car.mileage ? car.mileage.toString() : 'Not Specified'
    },
    {
      feature: 'VIN',
      value: car.vin ? car.vin : 'Not Specified'
    },
    {
      feature: 'Title Type',
      value: (car['car.title'] && car['car.title'].attributeValue !== '') ? car['car.title'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Exterior Color',
      value: (car['car.exteriorColor'] && car['car.exteriorColor'].attributeValue !== '') ? car['car.exteriorColor'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Interior Color',
      value: (car['car.interiorColor'] && car['car.interiorColor'].attributeValue !== '') ? car['car.interiorColor'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Transmission',
      value: (car['car.transmission'] && car['car.transmission'].attributeValue !== '') ? car['car.transmission'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Liters',
      value: (car['car.liter'] && car['car.liter'].attributeValue !== '') ? car['car.liter'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Cylinders',
      value: (car['car.cylinder'] && car['car.cylinder'].attributeValue !== '') ? car['car.cylinder'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Fuel Type',
      value: (car['car.fuel'] && car['car.fuel'].attributeValue !== '') ? car['car.fuel'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Numbers of Doors',
      value: (car['car.numberDoor'] && car['car.numberDoor'].attributeValue !== '') ? car['car.numberDoor'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Exterior Condition',
      value: (car['car.exteriorCondition'] && car['car.exteriorCondition'].attributeValue !== '') ? car['car.exteriorCondition'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Interior Condition',
      value: (car['car.interiorCondition'] && car['car.interiorCondition'].attributeValue !== '') ? car['car.interiorCondition'].attributeValue : 'Not Specified'
    },
    {
      feature: 'Drive',
      value: (car['car.drive'] && car['car.drive'].attributeValue !== '') ? car['car.drive'].attributeValue : 'Not Specified'
    }
  ]
}

function processImages(srcList, positionList, doc,closeSpinner) {
  srcList.forEach( async (src, index) => {
    if(src !== "noImage"){
      await toDataURL(src,
        function(image, width, height,callBack) {
          let percent  = width / height;
          const widthImage = (positionList[index].width === 1) ? positionList[index].height*percent : positionList[index].width
          doc.addImage(image, 'PNG', positionList[index].x, positionList[index].y, widthImage, positionList[index].height, `image-${index}`, 'NONE', 0);
        },
        closeSpinner
      );
    }else{
      doc.setFillColor(226, 226, 226)
      doc.rect(20 + 140*(index - 5), 40, 130, 84.5, 'F')
      doc.setTextColor(127,127,127)
      doc.text('No image', 60 + 140*(index -5), 82.25);
    }
    if(index === srcList.length - 1){
      let blob = doc.output("blob");
      window.open(URL.createObjectURL(blob));
      closeSpinner()
    }
  });
}

function createHeaders(keys) {
  return (
    keys.map(el => {
      return ({
        id: el,
        name: el,
        prompt: el,
        width: '65',
        align: 'center',
        padding: '0'
      })
    })
  );
}
